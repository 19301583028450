import { Link, Trans } from 'gatsby-plugin-react-i18next';
import React from "react"
import { ContentContainer } from "../components/Container";
import { Footer } from "../components/Footer"
import { Main } from "../components/Main";
import { Menubar } from "../components/Menu"
import { graphql } from 'gatsby'

function NotFound404() {
	return (
		<>
			<Main>
				<Menubar />
				<ContentContainer className="mt-12">
					<p className="text-center text-gray-400 text-md px-2">
						<img src="/assets/404.png" className="inline-block" alt="Hero looking confused" />
						<span className="block mt-4">
							<Trans i18nKey="title">
								Error
							</Trans>
						</span>
						<Trans i18nKey="message">
							The information you requested could not be found.
						</Trans>
						<Link className="block text-fl-white text-lg mt-4" to="/">
							<Trans i18nKey="back">
								⬅ Go Back
							</Trans>
						</Link>
					</p>
				</ContentContainer>
				<Footer />
			</Main>
		</>
	)
}

export default NotFound404;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;